// @flow

import styled from '@emotion/styled'
import { useFormik } from 'formik'
import { Link } from 'gatsby'
import * as React from 'react'
import { colors, media } from '@toggl/style'
import { Form, Button, text, Icon, Link as UiLink } from '@toggl/ui'
import { useAuth, useSession } from '@toggl/auth'

import IndexLayout from 'layouts'
import LayoutTrack from './LayoutTrack'
import GoogleLogin from './GoogleLogin'
import AppleLogin from './AppleLogin'

declare var process: WebProcess

const validate = (values) => {
  const errors = {}

  if (!values.email) {
    errors.email = 'required'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'invalid email address'
  }

  if (!values.password) {
    errors.password = 'required'
  }
  return errors
}

type Props = {
  +onSuccess: () => void,
  +Hero: React.ComponentType<any>,
  title?: string,
  description?: string,
  ogImage?: string,
}

const LoginForm = ({ onSuccess, Hero, title, description, ogImage }: Props) => {
  const [, session] = useSession()
  const [ready, auth] = useAuth()
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    validateOnChange: false,
    // $FlowFixMe useFormik type definition is outdated
    onSubmit: async (values) => {
      try {
        const { id } = await auth.login(values)
        session.create(id)

        onSuccess()
      } catch (e) {
        formik.setFieldError('form', e.message)
      }
    },
  })

  return (
    <IndexLayout title={title} description={description} ogImage={ogImage}>
      <LayoutTrack Hero={Hero}>
        <Root>
          <StyledForm error={formik.errors.form} onSubmit={formik.handleSubmit}>
            <Form.TwoFieldWrapper>
              <GoogleLogin
                onError={(message) => formik.setFieldError('form', message)}
              />
              <AppleLogin
                onError={(message) => formik.setFieldError('form', message)}
              />
            </Form.TwoFieldWrapper>
            <Form.Input
              id="email"
              label={<label htmlFor="email">Email</label>}
              name="email"
              type="email"
              placeholder={'Email'}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.email}
              error={
                formik.errors.email ? <div>{formik.errors.email}</div> : null
              }
            />

            <Form.Input
              id="password"
              label={<label htmlFor="password">Password</label>}
              name="password"
              type="password"
              placeholder={'Password'}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.password}
              error={
                formik.errors.password ? (
                  <div>{formik.errors.password}</div>
                ) : null
              }
            />

            <ForgotPasswordWrapper>
              <Link to="/track/forgot-password">
                <UiLink.Underlined>Forgot password?</UiLink.Underlined>
              </Link>
            </ForgotPasswordWrapper>

            <SsoWrapper>
              <SsoButton>
                <Icon.Lock />
                Company login (SSO)
              </SsoButton>
            </SsoWrapper>
            <Button.Primary
              type="submit"
              disabled={!ready || formik.isSubmitting}
            >
              Submit
            </Button.Primary>
          </StyledForm>

          <SignupCta>
            <span>Dont have an account already?</span>
            <Link to={'/track/signup'}>
              <Button.Primary>Sign up for free</Button.Primary>
            </Link>
          </SignupCta>
        </Root>
      </LayoutTrack>
    </IndexLayout>
  )
}

const SsoWrapper = styled.div``
const SsoButton = styled(Button.SocialLogin)`
  background: transparent;
  color: ${colors.beige};
  svg {
    width: 20px;
    margin-right: 10px;
  }
`

const Root = styled.div`
  padding-bottom: 130px;
  background-color: ${colors.fadedPurple};
`

const SignupCta = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  span {
    ${text.paragraph2};
    color: ${colors.almostWhite};
    margin-bottom: 30px;
  }
`

const StyledForm = styled(Form.Wrapper)`
  padding: 26px 16px;
  ${media.mq[1]} {
    width: 673px;
    padding: 58px 45px 64px 45px;
    background-color: ${colors.darkPurple};
    margin: 0 auto;
    transform: translateY(-30px);
    position: relative;
    z-index: 10000;
  }
`

const ForgotPasswordWrapper = styled.div`
  text-align: right;
  margin-top: 3px;
`

export default LoginForm
