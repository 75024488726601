// @flow
import * as React from 'react'

import { Hero } from '@toggl/ui'
import LoginForm from 'components/LoginForm'
import background from 'images/uploads/hero-laptops.jpg'

const onSuccess = () => window.location.assign(process.env.GATSBY_TRACK_APP_URL)

const LoginPage = () => {
  const HeroComponent = ({ children }) => (
    <Hero.Wrapper bgImage={background}>
      {children}

      <Hero.LoginWrapper>
        <Hero.Title centered>{'Get Tracking'}</Hero.Title>
        <Hero.LoginSmallTitle centered>
          {'Login to your Toggl Track account'}
        </Hero.LoginSmallTitle>
      </Hero.LoginWrapper>
    </Hero.Wrapper>
  )
  return (
    <LoginForm
      title={'Toggl Track: Login to your account'}
      description={
        'Login to your Toggl Track account and dive into effortless time-tracking and powerful reporting.'
      }
      onSuccess={onSuccess}
      Hero={HeroComponent}
    />
  )
}

export default LoginPage
